import axios from 'axios';

// 创建一个axios实例
const network = axios.create({
  // 请求超时时间
  timeout: 3000,
  baseURL: process.env.VUE_APP_BASEURL,
})

export default network
